import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import graph from '../components/charts/api/reducers'
import tabs from '../components/tabs/api/reducers'

export const reducersApi = {
  graph,
  tabs,
}

const AppReducer = (history) => combineReducers({
  router: connectRouter(history),
  ...reducersApi,
})

export default AppReducer
