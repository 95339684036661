export const eurFormatter = new Intl.NumberFormat('fr-FR', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0, 
})
  
export const numFormat = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 2, 
})
  
  export const percentFormatter = new Intl.NumberFormat('fr-FR', {
    style: 'percent',
    minimumFractionDigits: 0, 
  })
  
  export const numbersFormatter = (num) => {
    return Number(num.toFixed(2)).toLocaleString().split(/\s/).join(' ') + ',' + Number(num.toString().slice(num.toString().indexOf('.') + 1)).toLocaleString()
  }
  
  
  export default { eurFormatter, percentFormatter, numbersFormatter }
  