import React from 'react'
import Billet from '../../../assets/images/billet-vert.png';
import Euro from '../../../assets/images/euro-vert.png';
import Caisse from '../../../assets/images/caisse-vert.png';
import Carte from '../../../assets/images/carte-vert.png';
import Graph from '../../../assets/images/graph-vert.png';
import Loupe from '../../../assets/images/loupe-vert.png';
import Panier from '../../../assets/images/panier-vert.png';
import Parametre from '../../../assets/images/parametre-vert.png';
import Voiture from '../../../assets/images/voiture-vert.png';
import { eurFormatter } from '../../../utils/formatNumber'

const setIcon = (icon) => {
  if (icon === 'Billet') return <img src={Billet} alt="Billet" />
  if (icon === 'Euro') return <img src={Euro} alt="Euro" />
  if (icon === 'Caisse') return <img src={Caisse} alt="Caisse" />
  if (icon === 'Carte') return <img src={Carte} alt="Carte" />
  if (icon === 'Graph') return <img src={Graph} alt="Graphique" />
  if (icon === 'Loupe') return <img src={Loupe} alt="Loupe" />
  if (icon === 'Panier') return <img src={Panier} alt="Panier" />
  if (icon === 'Parametre') return <img src={Parametre} alt="Paramètre" />
  if (icon === 'Voiture') return <img src={Voiture} alt="Voiture" />
  return
}

const IconData = (props) => {
  const { data, keys } = props
  const { icon, value, unite } = data
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 15,
        background: 'white',
        borderRadius: 10,
        minHeight: 'auto'
      }}
    >
      <div style={{ width: 47, margin: '5px 10px' }}>{setIcon(icon)}</div>
      {
        !isNaN(value) ? `${eurFormatter.format((value).toFixed(2))} ${unite}` : value
      }
    </div>
  )
}
//!isNaN(value) ? `${eurFormatter.format((value/keys.length).toFixed(2))} ${unite}` : value
export default IconData
