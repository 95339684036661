import React from "react";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "../../../components/header/parts/templatePage";
import TableoLogo from "../../../assets/images/logo_tableo.png";
import dayjs from "dayjs";
import Snackbar from "@material-ui/core/Snackbar";
import { baseURL } from "../../../config";
import _ from "lodash";

export default function PdfExport(props) {
  const {
    cabinet,
    display,
    allData,
    classes,
    open,
    handleClose,
    Alert,
    pdfExportComponent,
    container,
  } = props;
  const { nom, logo, email, dossier } = cabinet;
  const { width } = useWindowDimensions();
  const dateDebut = new URLSearchParams(location.search).get("date_debut");
  const dateFin = new URLSearchParams(location.search).get("date_fin");
  return (
    <div
      style={{ position: "absolute", left: "-6000px", top: 0, width: "100%" }}
    >
      <PDFExport
        forcePageBreak=".page-break"
        landscape
        pageTemplate={PageTemplate}
        fileName={`restitution-client-${dayjs().format("DD/MM/YYYY")}`}
        paperSize="A4"
        margin="1cm"
        scale={width > 1920 ? 0.5 : 0.6}
        ref={pdfExportComponent}
      >
        <div ref={container}>
          <div
            style={{
              margin: 20,
              marginTop: 40,
            }}
          >
            <img className="logo-tab" src={TableoLogo} />
            <div className="tab-container" style={{ height: "72.2vh" }}>
              <div className="cabinet-logo">
                <img
                  style={{ width: 150 }}
                  className="logo-tab"
                  src={`${baseURL}/${logo.replace("../", "")}`}
                />
              </div>
              <div className="cabinet-details">
                <h4>{email}</h4>
                <h4>{nom}</h4>
              </div>
              <div className="tableo-container">
                <h2 className="tableo-title">Tableau de Bord</h2>
                {dossier.logo !== null ? (
                  <div className="cabinet-logo dossier">
                    <img
                      style={{ width: 150 }}
                      className="logo-tab"
                      src={`${baseURL}/${dossier.logo.replace("..", "")}`}
                    />
                  </div>
                ) : null}
                <div className="tableo-exercice">
                  <h4 style={{ fontSize: 16 }}>
                    {`Exercice de ${dateDebut.replace(
                      "-",
                      "/"
                    )} à ${dateFin.replace("-", "/")}`}
                  </h4>
                  <h4 style={{ fontSize: 16 }}>Dossier : {dossier.nom}</h4>
                  <h4 style={{ fontSize: 16 }}>
                    Votre contact : {dossier.referent}
                  </h4>
                </div>
              </div>
            </div>
            <h5>{dayjs().format("DD/MM/YYYY")}</h5>
          </div>
          <div style={{ fontSize: 14 }}>{display(allData, true)}</div>
        </div>
      </PDFExport>
      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Commentaire enregistré
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
