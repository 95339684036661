import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Home } from '../pages'

class App extends PureComponent {
  route = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => <Component {...props} />} />
  )

  render() {
    return (
      <Router>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          className="routes"
        >
          <this.route exact path="/:id?" component={Home} />
        </div>
      </Router>
    )
  }
}

export default withRouter(App)
