import React from 'react'
import ReactDOM from 'react-dom'
import '@babel/polyfill'
import Router from './redux/router'
import './assets/styles/index.scss'
import './assets/js'
import 'react-quill/dist/quill.snow.css'
import { CookiesProvider } from 'react-cookie'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
require('dotenv').config()

const rootElement = document.getElementById('root')
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <CookiesProvider>
      <Router />
    </CookiesProvider>,
    rootElement,
  )
} else {
  ReactDOM.render(
    <CookiesProvider>
      <Router />
    </CookiesProvider>,
    rootElement,
  )
}
