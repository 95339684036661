import { eurFormatter } from '../../../utils/formatNumber'
export const mixOptions = (nbMonths, varia, rightAxis) => {
  return {
    responsive: true,
    interaction: {
      intersect: false,
    },
    maintainAspectRatio: false,
    scaleBeginAtZero: false,
    tooltips: {
      callbacks: {
        label: (tooltipItems, data) => {
          return (
            data.datasets[tooltipItems.datasetIndex].label +
            ': ' +
            eurFormatter.format(parseFloat(tooltipItems.yLabel).toFixed(2)) +
            ` ${data.datasets[tooltipItems.datasetIndex].unite}`
          )
        },
        title: () => {
          return null
        },
      },
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          barThickness: "flex",
          display: true,
          gridLines: {
            display: false,
            offsetGridLines: true,
          },
          labels: nbMonths,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: false,
            callback: function (value) {
              return value.toLocaleString()
            },
          },
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: true,
          },
          labels: {
            show: true,
          },
        },
        {
          ticks: {
            beginAtZero: false,
            callback: function (value) {
              return value.toLocaleString()
            },
          },
          type: 'linear',
          scaleLabel: {
            display: true,
            labelString: '',
          },
          display: rightAxis,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: true,
          },
          labels: {
            show: true,
          },
        },
      ],
    },
  }
}

export const stackOptions = {
  scaleBeginAtZero: false,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
}
