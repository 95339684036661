import { all, fork } from 'redux-saga/effects'
import graph from '../components/charts/api/sagas'

const sagas = [
  graph,
]

function* rootSaga() {
  yield all(
    sagas.map((saga) => fork(saga)),
  )
}

export default rootSaga
