import React from 'react'
import { Pie } from 'react-chartjs-2'
import setOptions from './options'

const PieChart = (props) => {
  const { data } = props
  return (
    <div
      className="widget graph"
      style={
        props.isPdf
          ? {
              width: '55%',
              height: 350,
            }
          : null
      }
    >
      <Pie data={data} options={setOptions()} />
    </div>
  )
}

export default PieChart
