import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import setOptions from './options'

const DonutChart = (props) => {
  // const data = {
  //   labels: [
  //     'Salaires',
  //     'Achat Matière 1re',
  //     'Loyer'
  //   ],
  //   datasets: [{
  //     data: [144000, 141666, 72000],
  //     backgroundColor: [
  //       '#3C6E71',
  //       '#A7E2FF',
  //       '#F4CBC6'
  //     ],
  //     hoverBackgroundColor: [
  //       '#3C6E71',
  //       '#A7E2FF',
  //       '#F4CBC6'
  //     ]
  //   }]
  // }
  const { data } = props
  return (
    <div
      className="widget graph"
      style={
        props.isPdf
          ? {
              width: '55%',
              height: 350,
            }
          : null
      }
    >
      <Doughnut data={data} options={setOptions()} />
    </div>
  )
}

export default DonutChart
