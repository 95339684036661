import React, { useState } from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { ReactComponent as AddIconWhite } from '../../assets/images/ajout_bouton_blanc.svg'
import { ReactComponent as PDFIcon } from '../../assets/images/pdf.svg'
import { withRouter } from 'react-router'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import fr from 'date-fns/locale/fr'
import dayjs from 'dayjs'

registerLocale('fr', fr)

function Header(props) {
  const {
    id,
    status,
    modifiable,
    pdfExportComponent,
    history,
    dateDebut,
    setNewDate,
    dateFin,
    location,
  } = props
  const debut = dateDebut
    ? dateDebut
    : dayjs().subtract(1, 'year').format('MM-YYYY')
  const fin = dateFin
    ? dateFin
    : dayjs().add(1, 'month').endOf('month').format('MM-YYYY')
  const { width } = useWindowDimensions()
  const [startDate, setStartDate] = useState(
    new Date(`${debut.slice(0, 2)}/01/${debut.slice(3, 7)}`),
  )
  const [endDate, setEndDate] = useState(
    new Date(`${fin.slice(0, 2)}/01/${fin.slice(3, 7)}`),
  )

  const createGraph = (e, url) => {
    const { addLink } = props
    self.parent.location = addLink
  }

  const onChangeStart = (date) => {
    setStartDate(date)
    setNewDate('debut', dayjs(date).format('MM-YYYY'))
  }

  const onChangeEnd = (date) => {
    setEndDate(date)
    setNewDate('fin', dayjs(date).format('MM-YYYY'))
  }
  return (
    <header className="header">
      <h1 onClick={() => {
        id ? history.push(`${location.pathname}${location.search}`) : history.push('/')
      }} className="title">Tableau de bord</h1>
      {id && width > 640 ? (
        <div className="container">
          {status && status !== 404 && status !== 500 ? (
            <>
              <div className="datepickers">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => onChangeStart(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={endDate}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                  locale="fr"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => onChangeEnd(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="MM/yyyy"
                  locale="fr"
                  showMonthYearPicker
                />
              </div>
              <div className="buttons">
                <a
                  onClick={() => {
                    if (pdfExportComponent.current) {
                      pdfExportComponent.current.save();
                    }
                  }}
                  className="btn-tableo blanc"
                >
                  <PDFIcon />
                  <span className="text">Exporter en PDF</span>
                </a>
                {modifiable ? (
                  <a onClick={createGraph} className="btn-tableo">
                    <AddIconWhite />
                    <span className="text">Ajouter un nouveau graphique</span>
                  </a>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </header>
  )
}

export default withRouter(Header)
