import React from 'react'

const Error = (props) => {
  const { data } = props
  const { value } = data
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 36,
      }}
    >
      {value}
    </div>
  )
}

export default Error
