const http = window.location.href.indexOf("https") >= 0 ? "https" : "https"
const isLocal = window.location.href.indexOf("localhost") >= 0
const isPreprod = window.location.href.indexOf("coi.im") >= 0

export const baseURL =
  isLocal || isPreprod ? `${http}://tableo.coi.im` : `${http}://tableo.fr`

export const baseDomain = isLocal || isPreprod ? ".coi.im" : ".tableo.fr"

// utiliser les données de prod pour les tests uniquement
// export const baseURL = "https://tableo.fr"
 
export default baseURL
