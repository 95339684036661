import React from 'react'
import { Radar } from 'react-chartjs-2'
import { options } from './options'

const RadarChart = (props) => {
  const datasets = {
    labels: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Aout',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    datasets: props.data.datasets,
  }
  return (
    <div
      className="widget graph"
      style={
        props.isPdf
          ? {
              width: '55%',
              height: 350,
            }
          : null
      }
    >
      <Radar data={datasets} options={options()} />
    </div>
  )
}

export default RadarChart
