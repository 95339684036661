import { eurFormatter } from '../../../utils/formatNumber'

function setOptions() {
  return {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex]
          const meta = dataset._meta[Object.keys(dataset._meta)[0]]
          const total = meta.total
          const currentValue = dataset.data[tooltipItem.index]
          const percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1),
          )
          return `${eurFormatter.format(currentValue
            .toFixed(2)
            .toLocaleString())} ${data.datasets[0].unite[tooltipItem.index]} (${percentage.toFixed(1)} %)`
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]
        },
      },
    },
  }
}

export default setOptions
