import {
  GRAPHS_DATA,
} from './actions'

const initialState = {
  dataGraphs: null,
  dataCalled: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GRAPHS_DATA:
      return {
        ...state,
        dataGraphs: action.dataGraphs,
        dataCalled: true,
      }
    default:
      return state
  }
}
