import { eurFormatter } from '../../../utils/formatNumber'

export const options = () => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItems, data) => {
          return (
            tooltipItems.yLabel +
            ': ' +
            eurFormatter.format(parseFloat(tooltipItems.value).toFixed(2)) +
            ` ${data.datasets[0].unite[tooltipItems.index]}`
          )
        },
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          categoryPercentage: 0.75,
          barPercentage: 0.5,
          stacked: true,
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
  }
}
