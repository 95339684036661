import axios from 'axios'
import { baseURL } from '../../../config'
import moment from 'moment'
import { toEncode } from '../../../utils/toEncode'


const keyToEncode = `${moment().format('YYYYMMDD')}${process.env.REACT_APP_API_ACCESS}`
const token = toEncode(keyToEncode)

const getGraphs = ({ id, modif, dateDebut, dateFin }) =>
  axios({
    method: 'get',
    headers: {
      "token": token
    },
    baseURL: `${baseURL}/api/graphiques/${id}${modif === 'yes' ? '?modif=yes' : '/'
      }${modif === 'yes' ? '&' : '?'}date_debut=${dateDebut}&date_fin=${dateFin}`,
    timeout: 5000,
  })

const postComment = ({ id, content, date_debut, date_fin }) => {
  const bodyFormData = new FormData()
  bodyFormData.append('content', content)
  bodyFormData.append('date_debut', date_debut)
  bodyFormData.append('date_fin', date_fin)
  axios({
    method: "post",
    headers: {
      "token": token
    },
    url: `${baseURL}/api/graphiques/page/${id}/comment`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      document.location.href = location.href + "&save=true";
      console.log(response)
    })
    .catch((response) => {
      console.log(response)
    });
}

export default { getGraphs, postComment }
