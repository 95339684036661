import { call, put, takeEvery } from 'redux-saga/effects'
import me from '.'
import { getGraphsResults } from './actions'

const defaultError = {
  type: 'TOASTER',
  item: {
    body: 'Une erreur est survenue',
    validity: 5,
    type: 'error',
  },
}

function* getGraphsAsync(params) {
  try {
    const response = yield call(me.getGraphs, params)
    yield put(getGraphsResults(response))
  } catch ({ response }) {
    yield put(defaultError)
    yield put(getGraphsResults(response))
  }
}

export function* fetchResult() {
  yield takeEvery('GRAPHS_LIST', getGraphsAsync)
}

export default fetchResult
