import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import _ from 'lodash'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const setColors = (index) => {
  if (index === 0) return '#A7E2FF'
  if (index === 1) return '#F4CBC6'
  if (index === 2) return '#3C6E71'
  return '#F4CBC6'
}

function LineCharts(props) {
  const { width } = useWindowDimensions()
  const { data } = props
  return (
    <LineChart
      width={width <= 640 ? width - 35 : width - 110}
      height={300}
      data={data}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {_.map(data[0].keys, (key, index) => (
        <Line
          type="monotone"
          dataKey={key}
          stroke={setColors(index)}
          strokeWidth={2.5}
        />
      ))}
    </LineChart>
  )
}

export default LineCharts
