import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import { options } from './options'

// const data = {
//   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//   datasets: [
//     {
//       label: 'My First dataset',
//       borderWidth: 1,
//       data: [65, 59, 80],
//       backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
//       hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
//     },
//   ],
// }

const Horizontal = (props) => {
  const { data } = props
  return (
    <div
      className="widget graph"
      style={
        props.isPdf
          ? {
              width: '55%',
              height: 350,
            }
          : null
      }
    >
      <HorizontalBar data={data} options={options()} />
    </div>
  )
}

export default Horizontal
