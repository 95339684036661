import React from "react";
import { Bar } from "react-chartjs-2";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { mixOptions } from "./options";
import _ from "lodash";

const setMixOptions = (mixOptions, width, months, variation) => {
  const veryLarge = {
    ...mixOptions,
    scales: {
      ...mixOptions.scales,
      xAxes: [
        {
          ...mixOptions.scales.xAxes[0],
          barThickness: "flex",
        },
      ],
    },
  };
  const large = {
    ...mixOptions,
    scales: {
      ...mixOptions.scales,
      xAxes: [
        {
          ...mixOptions.scales.xAxes[0],
          barThickness: "flex",
        },
      ],
    },
  };

  const medium = {
    ...mixOptions,
    scales: {
      ...mixOptions.scales,
      xAxes: [
        {
          ...mixOptions.scales.xAxes[0],
          barThickness: "flex",
        },
      ],
    },
  };

  const small = {
    ...mixOptions,
    scales: {
      ...mixOptions.scales,
      xAxes: [
        {
          ...mixOptions.scales.xAxes[0],
          barThickness: "flex",
        },
      ],
    },
  };

  if (width >= 1400) return veryLarge;
  if (width >= 1200) return large;
  if (width <= 960 && width > 640) return medium;
  if (width <= 640) return small;
  return large;
};

const selectOptions = (type, variation, nbMonths, width, length, rightAxis) => {
  if (type === "stacked") {
    return setMixOptions(
      mixOptions(nbMonths, "stacked", rightAxis),
      width,
      nbMonths,
      "stacked",
      length
    );
  }
  return setMixOptions(
    mixOptions(nbMonths, null, rightAxis),
    width,
    nbMonths,
    null,
    length
  );
};

const BarChart = (props) => {
  const { width } = useWindowDimensions();
  const type = props.type;
  const isPdf = props.isPdf;
  const variation = props.variation;
  const datasets = { datasets: props.data };
  const length = props.data.length;
  if (_.isEmpty(props.data)) {
    return false;
  } else {
    const rightAxis = props.data[0].yAxisID === "y-axis-2";
    return (
      <div
        className="widget graph"
        style={
          isPdf
            ? {
                width: "66%",
                height: 350,
              }
            : null
        }
      >
        <Bar
          data={datasets}
          options={selectOptions(
            type,
            variation,
            props.keys,
            width,
            length,
            rightAxis
          )}
        />
      </div>
    );
  }
};

export default BarChart;
