import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tabs, Header } from "../../components";
import Charts from "./parts/chartsRendEngine";
import _ from "lodash";
import dayjs from "dayjs";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { baseDomain } from "../../config";

// fakeData utils for testing purpose only
// import { fakeData } from "../../utils/fakeData";
class Home extends React.PureComponent {
  static propTypes = {
    GRAPHS_LIST: PropTypes.func.isRequired,
    dataGraphs: PropTypes.objectOf(PropTypes.any),
    dataCalled: PropTypes.bool.isRequired,
    active: PropTypes.number,
    cookies: instanceOf(Cookies).isRequired,
  };

  static defaultProps = {
    dataGraphs: null,
    active: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      pdfExportComponent: null,
      callAgain: false,
      dateDebutState: "",
      dateFinState: "",
    };
  }

  componentDidMount() {
    const { history, location, ACTIVE_SELECTED, match, GRAPHS_LIST, cookies } =
      this.props;
    const { id } = match.params;
    const modif = new URLSearchParams(location.search).get("modif");
    const pageNum = new URLSearchParams(location.search).get("page");
    const commentSaved = new URLSearchParams(location.search).get("save");
    const dateDebut = new URLSearchParams(location.search).get("date_debut")
      ? new URLSearchParams(location.search).get("date_debut")
      : dayjs().subtract(1, "year").format("MM-YYYY");
    const dateFin = new URLSearchParams(location.search).get("date_fin")
      ? new URLSearchParams(location.search).get("date_fin")
      : dayjs().add(1, "month").endOf("month").format("MM-YYYY");
    this.setState({ dateDebutState: dateDebut, dateFinState: dateFin });
    GRAPHS_LIST(id, modif, dateDebut, dateFin);
    cookies.set("date_debut", dateDebut, { domain: baseDomain });
    cookies.set("date_fin", dateFin, { domain: baseDomain });
    cookies.set("old_url", window.location.href);
    localStorage.removeItem("comments");
    if (location.search) {
      if (pageNum && modif && commentSaved) {
        history.push(
          `/${id}?page=${pageNum}&date_debut=${dateDebut}&date_fin=${dateFin}&modif=${modif}&save=${commentSaved}`
        );
      } else {
        if (pageNum && commentSaved && modif === null) {
          history.push(
            `/${id}?page=${pageNum}&date_debut=${dateDebut}&date_fin=${dateFin}&save=${commentSaved}`
          );
        }
        if (pageNum === null && modif && commentSaved)
          history.push(
            `/${id}?page=1&date_debut=${dateDebut}&date_fin=${dateFin}&modif=${modif}&save=${commentSaved}`
          );
        if (pageNum === null && modif === null && commentSaved)
          history.push(
            `/${id}?page=1&date_debut=${dateDebut}&date_fin=${dateFin}&save=${commentSaved}`
          );
        if (pageNum === null && modif && commentSaved === null)
          history.push(
            `/${id}?page=1&date_debut=${dateDebut}&date_fin=${dateFin}&modif=${modif}`
          );
        if (pageNum === null && modif === null && commentSaved === null)
          history.push(
            `/${id}?page=1&date_debut=${dateDebut}&date_fin=${dateFin}`
          );
      }
      ACTIVE_SELECTED(pageNum - 1);
    } else {
      if (!id) return;
      history.push(`/${id}?page=1&date_debut=${dateDebut}&date_fin=${dateFin}&save=${commentSaved}`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match,
      GRAPHS_LIST,
      dataGraphs,
      location,
      history,
      cookies,
      active,
    } = this.props;
    const { callAgain, dateDebutState, dateFinState, comments } = this.state;
    const { callAgain: callAgainOld, comments: commentsOld } = prevState;
    const { id } = match.params;
    const pageNum = new URLSearchParams(location.search).get("page");

    const modif = new URLSearchParams(location.search).get("modif");
    const dateDebut = new URLSearchParams(location.search).get("date_debut");
    const dateFin = new URLSearchParams(location.search).get("date_fin");
    const { active: activeOld } = prevProps;
    if (!_.isEqual(window.location.href, cookies.get("old_url"))) {
      cookies.set("page", pageNum, { domain: baseDomain });
      cookies.set("old_url", window.location.href);
    }
    if (!_.isEqual(active, activeOld) && dataGraphs) {
      GRAPHS_LIST(id, modif, dateDebut, dateFin);
      if (dataGraphs.data.pages.length === 1) {
        cookies.set("page", 1, { domain: baseDomain });
      } else {
        cookies.set("page", active + 1, { domain: baseDomain });
      }
    }
    if (id && !_.isEqual(dateDebut, dateDebutState)) {
      cookies.set("date_debut", dateDebutState, { domain: baseDomain });
    }
    if (id && !_.isEqual(dateFin, dateFinState)) {
      cookies.set("date_fin", dateFinState, { domain: baseDomain });
    }

    if (
      !_.isEqual(callAgain, callAgainOld) &&
      callAgain &&
      dateDebutState &&
      dateFinState
    ) {
      GRAPHS_LIST(id, modif, dateDebutState, dateFinState);
      if (location.search) {
        if (pageNum && modif) {
          history.push(
            `/${id}?page=${pageNum}&date_debut=${dateDebutState}&date_fin=${dateFinState}&modif=${modif}`
          );
        } else {
          if (pageNum && modif === null) {
            history.push(
              `/${id}?page=${pageNum}&date_debut=${dateDebutState}&date_fin=${dateFinState}`
            );
          }
          if (pageNum === null && modif) {
            history.push(
              `/${id}?page=1&date_debut=${dateDebutState}&date_fin=${dateFinState}&modif=${modif}`
            );
          }
          if (pageNum === null && modif === null) {
            history.push(
              `/${id}?page=1&date_debut=${dateDebutState}&date_fin=${dateFinState}`
            );
          }
        }
      } else {
        if (!id) return;
        history.push(
          `/${id}?page=1&date_debut=${dateDebutState}&date_fin=${dateFinState}`
        );
      }
      this.setState({ callAgain: false });
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("comments");
  }

  setNewDate = (type, date) => {
    if (type === "debut") {
      this.setState({ dateDebutState: date, callAgain: true });
    }
    if (type === "fin") {
      this.setState({ dateFinState: date, callAgain: true });
    }
  };

  getExport = (childToParentRef) => {
    this.setState({ pdfExportComponent: childToParentRef });
  };

  makeTabs = () => {
    const { dataGraphs, match } = this.props;
    const { id } = match.params;
    const { pages, cabinet, exportUser } = dataGraphs.data;
    const pageId = new URLSearchParams(location.search).get("page");
    const commentSaved = new URLSearchParams(location.search).get("save");
    let tabsPanel = [];
    const allComments = [];
    pages.map((elem, key) =>
      allComments.push({
        page: elem.page,
        comment: elem.comments,
        allPageComments: elem.allPageComments,
      })
    );

    if (allComments && allComments.length === pages.length) {
      pages.map((elem, key) => {
        tabsPanel.push({
          label: elem.pageName,
          display: () => (
            <Charts
              data={pages[key]}
              comments={
                localStorage.getItem("comments")
                  ? JSON.parse(localStorage.getItem("comments"))
                  : allComments
              }
              all={
              localStorage.getItem("allPageComments")
                ? JSON.parse(localStorage.getItem("allPageComments"))
                : null
            }
              allData={pages}
              cabinet={cabinet}
              exportUser={exportUser}
              getExport={this.getExport}
              id={id}
              pageId={pageId}
              commentSaved={commentSaved}
              modifiable={dataGraphs.data.modifiable}
            />
          ),
        });
      });
    }
    return tabsPanel;
  };

  display = () => {
    const { dataGraphs, match } = this.props;
    const { pdfExportComponent } = this.state;
    const { location } = this.props;
    const currentPage = new URLSearchParams(location.search).get("page") - 1;
    const { id } = match.params;
    const modif = new URLSearchParams(location.search).get("modif");
    const dateDebut = new URLSearchParams(location.search).get("date_debut");
    const dateFin = new URLSearchParams(location.search).get("date_fin");

    if (dataGraphs !== null && dataGraphs.status) {
      if (dataGraphs.status === 200) {
        if (
          (dataGraphs.data.modifiable && !_.isEmpty(dataGraphs.data.pages)) ||
          !_.isEmpty(dataGraphs.data.pages)
        ) {
          return (
            <div style={{ minHeight: "100vh" }}>
              <nav>
                <Header
                  modifiable={modif}
                  dateDebut={dateDebut}
                  dateFin={dateFin}
                  id={id}
                  addLink={dataGraphs.data.pages[currentPage].addLink}
                  status={200}
                  pdfExportComponent={pdfExportComponent}
                  graphs={dataGraphs.data.pages || null}
                  setNewDate={this.setNewDate}
                />
                <Tabs
                  subheader
                  tabsPanels={this.makeTabs()}
                  modifiable={modif}
                  dateDebut={dateDebut}
                  dateFin={dateFin}
                />
              </nav>
              <div>
                <Tabs
                  panels
                  tabsPanels={this.makeTabs()}
                  modifiable={modif}
                  dateDebut={dateDebut}
                  dateFin={dateFin}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div style={{ minHeight: "100vh" }}>
              <nav>
                <Header
                  modifiable={modif}
                  setNewDate={this.setNewDate}
                  dateDebut={dateDebut}
                  dateFin={dateFin}
                  id={id}
                  status={404}
                  graphs={
                    !_.isEmpty(dataGraphs.data.pages)
                      ? dataGraphs.data.pages
                      : null
                  }
                />
                <Tabs
                  subheader
                  modifiable={modif}
                  dateDebut={dateDebut}
                  dateFin={dateFin}
                />
              </nav>
              <div>
                <div className="tabsPanels" style={{ minHeight: "100vh" }}>
                  <div style={{ textAlign: "center" }}>
                  Aucune donnée à afficher car aucune page n'est active
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
      if (dataGraphs.status === 500) {
        return (
          <div style={{ minHeight: "100vh" }}>
            <nav>
              <Header
                modifiable={modif}
                setNewDate={this.setNewDate}
                dateDebut={dateDebut}
                dateFin={dateFin}
                addLink={dataGraphs.data.pages[currentPage].addLink}
                status={500}
                id={id}
                graphs={null}
              />
              <Tabs
                subheader
                modifiable={modif}
                dateDebut={dateDebut}
                dateFin={dateFin}
              />
            </nav>
            <div>
              <div className="tabsPanels" style={{ minHeight: "100vh" }}>
                <div style={{ textAlign: "center" }}>
                  Erreur serveur impossible de récupérer les données
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (dataGraphs.status === 401) {
        return (
          <div style={{ minHeight: "100vh" }}>
            <nav>
              <Header
                modifiable={modif}
                setNewDate={this.setNewDate}
                dateDebut={dateDebut}
                dateFin={dateFin}
                status={500}
                id={id}
                graphs={null}
              />
              <Tabs
                subheader
                modifiable={modif}
                dateDebut={dateDebut}
                dateFin={dateFin}
              />
            </nav>
            <div>
              <div className="tabsPanels" style={{ minHeight: "100vh" }}>
                <div style={{ textAlign: "center" }}>
                  Impossible d'accéder aux données, essayer de rafraîchir la
                  page (F5)
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (dataGraphs.status === 404 || !id) {
        return (
          <div>
            <nav>
              <Header
                modifiable={modif}
                setNewDate={this.setNewDate}
                dateDebut={dateDebut}
                dateFin={dateFin}
                id={id}
                status={404}
                graphs={
                  !_.isEmpty(dataGraphs.data.pages)
                    ? dataGraphs.data.pages
                    : null
                }
              />
              <Tabs
                subheader
                modifiable={modif}
                dateDebut={dateDebut}
                dateFin={dateFin}
              />
            </nav>
            <div>
              <div className="tabsPanels" style={{ minHeight: "100vh" }}>
                <div style={{ textAlign: "center" }}>
                  {id === undefined
                    ? "Merci de bien vouloir préciser un numéro de dossier"
                    : `Aucun résultat pour le dossier n° ${id}`}
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <nav style={{ top: 0, left: 0, right: 0, position: "absolute" }}>
            <Header
              modifiable={modif}
              setNewDate={this.setNewDate}
              id={id}
              dateDebut={dateDebut}
              dateFin={dateFin}
            />
            <Tabs
              loading
              modifiable={modif}
              dateDebut={dateDebut}
              dateFin={dateFin}
            />
          </nav>
        </div>
      );
    }
    return (
      <div>
        <nav style={{ top: 0, left: 0, right: 0, position: "absolute" }}>
          <Header
            modifiable={modif}
            setNewDate={this.setNewDate}
            id={id}
            dateDebut={dateDebut}
            dateFin={dateFin}
          />
          <Tabs
            loading
            modifiable={modif}
            dateDebut={dateDebut}
            dateFin={dateFin}
          />
        </nav>
      </div>
    );
  };

  render() {
    return this.display();
  }
}

const mapStateToProps = (state) => ({
  dataGraphs: state.graph.dataGraphs,
  dataCalled: state.graph.dataCalled,
  active: state.tabs.active,
});

const mapDispatchToProps = (dispatch) => ({
  GRAPHS_LIST: (id, modif, dateDebut, dateFin) =>
    dispatch({ type: "GRAPHS_LIST", id, modif, dateDebut, dateFin }),
  ACTIVE_SELECTED: (active) => dispatch({ type: "ACTIVE_SELECTED", active }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(Home));
