import { ACTIVE_SELECTED } from './actions'

const initialState = {
  active: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_SELECTED:
      return {
        ...state,
        active: action.active,
      }
    case 'RESET_ACTIVE':
      return {
        ...state,
        active: null,
      }
    default:
      return state
  }
}
