import React from "react"

export default function PageTemplate(props) {
  const {pageNum, totalPages} = props
  return (
    <div
      style={{
        position: "absolute",
        bottom: "10px",
        right: "10px",
        fontSize: "13px",
      }}
    >
      Page {pageNum} sur {totalPages}
    </div>
  )
}
