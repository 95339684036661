import { eurFormatter } from '../../../utils/formatNumber'

export const options = () => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scale: {
      ticks: {
        beginAtZero: false,
        callback: function (value, index, values) {
          return value.toLocaleString()
        },
      },
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          return (
            data.datasets[tooltipItems.datasetIndex].label + ' : ' + 
            eurFormatter.format(parseFloat(tooltipItems.yLabel).toFixed(2)) +
            ` ${data.datasets[tooltipItems.datasetIndex].unite}`
          )
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]
        },
      },
    },
  }
}
