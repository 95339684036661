import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";
import _ from "lodash";
import {
  BarChart,
  HorizontalBarChart,
  RadarChart,
  DonutChart,
  PieChart,
  LineCharts,
  IconData,
  Error,
  Table,
  StackedBar
} from "../../../components";
import { ReactComponent as EditIcon } from "../../../assets/images/modifier.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/supprimer.svg";
import { ReactComponent as AddIconWhite } from "../../../assets/images/ajout_bouton_blanc.svg";
import { ReactComponent as SaveIcon } from "../../../assets/images/enregistrer.svg";
import { Editor } from '@tinymce/tinymce-react';
import api from "../../../components/charts/api";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import usePrevious from "../../../components/hooks/usePrevious";
import { confirmAlert } from "react-confirm-alert";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PdfExport from "./pdfExport";
import "react-confirm-alert/src/react-confirm-alert.css";

// Ne pas supprimer :
import tinymce from 'tinymce/tinymce';

import fr_FR  from '../../../utils/langs/fr_FR.js';
import 'tinymce/icons/default';
import 'tinymce/models/dom';
import 'tinymce/plugins/link';
import 'tinymce/plugins/template';
import 'tinymce/themes/silver';

import 'tinymce/skins/ui/tinymce-5/skin.min.css';
import 'tinymce/skins/ui/tinymce-5/content.min.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Charts(props) {
  const container = useRef(null);
  const pdfExportComponent = useRef(null);
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const { data, comments, modifiable, allData, active, commentSaved } = props;
  const [htmlComments, setHtml] = useState(comments);
  const [open, setOpen] = useState(commentSaved === "true");
  const prevData = usePrevious(data);
  const prevPage = usePrevious(active);
  const [editor, setEditor] = useState(null);

  const handleClick = () => {
    //setOpen(true);
    localStorage.setItem("comments", JSON.stringify(htmlComments));
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const mounted = useRef();
  const { getExport } = props;
  useEffect(() => {
    if (!_.isEqual(prevData, data)) {
      setHtml(comments);
    }
    if (!mounted.current) {
      mounted.current = true;
      getExport(pdfExportComponent);
    } else {
      getExport(pdfExportComponent);
    }
  });

  const setChart = (graphData, keys, type, isPdf = false) => {
    if (type.name === "TableChart") {
      return <Table data={graphData} keys={keys} isPdf={isPdf} />;
    }
    if (type.name === "HorizontalBar")
      return <HorizontalBarChart data={graphData} keys={keys} isPdf={isPdf} />;
    if (type.name === "BarChart") {
      //console.log("BarChart ======", graphData);
      return (
        <BarChart
          data={graphData}
          keys={keys}
          type={type.display}
          variation={type.variation}
          isPdf={isPdf}
        />
      );
    }
    if (type.name === "StackedBar") {
      //console.log("StackedBar ======", graphData);
      return <StackedBar data={graphData} keys={keys} isPdf={isPdf} />
    }
    if (type.name === "RadarChart")
      return <RadarChart data={graphData} keys={keys} isPdf={isPdf} />;
    if (type.name === "DonutChart")
      return <DonutChart data={graphData} isPdf={isPdf} />;
    if (type.name === "PieChart")
      return <PieChart data={graphData} isPdf={isPdf} />;
    if (type.name === "LineChart")
      return <LineCharts data={graphData} keys={keys} isPdf={isPdf} />;
    if (type.name === "IconData")
      return <IconData data={graphData} keys={keys} isPdf={isPdf} />;
    if (type.name === "error")
      return <Error data={graphData} isPdf={isPdf} />;
    return <h2>Ce type de graphique n'existe pas</h2>;
  };

  const editGraph = (e, url) => {
    self.parent.location = url;
  };

  const deleteGraph = (e, url) => {
    confirmAlert({
      title: "Confirmer la suppression ",
      message: "Voulez vous vraiment supprimer ce graphique ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => (self.parent.location = url),
        },
        {
          label: "Non",
        },
      ],
    });
  };

  const ToDisplay = (alldata, isPdf) => {
    const { pageId } = props;
    const whichSize = (size) => {
      if (size === "large") return 12;
      if (size === "medium") {
        if (width <= 640) {
          return 12;
        } else {
          return 6;
        }
      }
      if (size === "small") {
        if (width <= 640) {
          return 12;
        } else {
          return 3;
        }
      }
    };
    if (alldata.length === 1) {
      if (isPdf) {
        return alldata
          .map((p) => p)
          .map((page, pageIndex) =>
            _.sortBy(page.graphs, (i) => i.position).map((item, index) => {
              const lastIndex =
                alldata[pageIndex].graphs[alldata[pageIndex].graphs.length - 1]
                  .graphId === item.graphId;
              return (
                <Grid
                  className={
                    alldata[pageIndex].graphs[0].graphId === item.graphId
                      ? "page-break"
                      : alldata[pageIndex].graphs.find(
                        (i) => i.graphType.name === "TableChart"
                      )
                        ? "page-break"
                        : ""
                  }
                  key={index + 1}
                  item
                  xs={whichSize(item.graphSize)}
                >
                  <main className="graphs">
                    <div
                      className="widget"
                      style={
                        width <= 640
                          ? {
                            display: "flex",
                            flexDirection: width <= 640 ? "column" : "row",
                          }
                          : {
                            marginLeft: 5,
                          }
                      }
                    >
                      <div style={{ overflowX: "auto" }}>
                        <div
                          style={{
                            padding: 7,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h4 style={{ fontSize: 15 }}>{item.graphName}</h4>
                        </div>
                        {!(prevPage !== active) &&
                          setChart(
                            item.graphData,
                            item.keys,
                            item.graphType,
                            isPdf
                          )}
                      </div>
                    </div>
                  </main>
                  {lastIndex &&
                    htmlComments[pageIndex].comment !== "<p><br></p>" &&
                    htmlComments[pageIndex].comment !== "" ? (
                    <div>
                      <h4>Commentaire(s) collaborateur sur la période :</h4>
                      <div
                        style={{ margin: 20 }}
                        dangerouslySetInnerHTML={{
                          __html: htmlComments[pageIndex].comment,
                        }}
                      />
                    </div>
                  ) : null}
                </Grid>
              );
            })
          );
      }
      return alldata.map((page) =>
        _.sortBy(page.graphs, (i) => i.position).map((item, index) => {
          return (
            <Grid key={index + 1} item xs={whichSize(item.graphSize)}>
              <main className="graphs">
                <div
                  className="widget"
                  style={
                    width <= 640
                      ? {
                        display: "flex",
                        flexDirection: width <= 640 ? "column" : "row",
                      }
                      : {
                        marginLeft: 5,
                      }
                  }
                >
                  <div style={{ overflowX: "auto" }}>
                    <div
                      style={{
                        padding: 7,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4>{item.graphName}</h4>
                      {modifiable && !isPdf ? (
                        <div style={{ display: "flex" }}>
                          <EditIcon
                            onClick={(e) => editGraph(e, item.editLink)}
                            className="modifiableButton"
                          />
                          <DeleteIcon
                            onClick={(e) => deleteGraph(e, item.deleteLink)}
                            className="modifiableButton"
                          />
                        </div>
                      ) : null}
                    </div>
                    {setChart(item.graphData, item.keys, item.graphType, isPdf)}
                  </div>
                </div>
              </main>
            </Grid>
          );
        })
      );
    } else {
      if (isPdf) {
        return alldata
          .map((p) => p)
          .map((page, pageIndex) =>
            _.sortBy(page.graphs, (i) => i.position).map((item, index) => {
              const lastIndex =
                alldata[pageIndex].graphs[alldata[pageIndex].graphs.length - 1]
                  .graphId === item.graphId;
              return (
                <Grid
                  className={
                    alldata[pageIndex].graphs[0].graphId === item.graphId
                      ? "page-break"
                      : alldata[pageIndex].graphs.find(
                        (i) => i.graphType.name === "TableChart"
                      )
                        ? "page-break"
                        : ""
                  }
                  key={index + 1}
                  item
                  xs={whichSize(item.graphSize)}
                >
                  <main className="graphs">
                    <div
                      className="widget"
                      style={
                        width <= 640
                          ? {
                            display: "flex",
                            flexDirection: width <= 640 ? "column" : "row",
                          }
                          : {
                            marginLeft: 5,
                          }
                      }
                    >
                      <div style={{ overflowX: "auto" }}>
                        <div
                          style={{
                            padding: 7,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h4 style={{ fontSize: 15 }}>{item.graphName}</h4>
                        </div>
                        {!(prevPage !== active) &&
                          setChart(
                            item.graphData,
                            item.keys,
                            item.graphType,
                            isPdf
                          )}
                      </div>
                    </div>
                  </main>
                  {lastIndex &&
                    htmlComments[pageIndex].comment !== "<p><br></p>" &&
                    htmlComments[pageIndex].comment !== "" ? (
                    <div>
                      <h4>Commentaire(s) collaborateur sur la période :</h4>
                      <div
                        style={{ margin: 20 }}
                        dangerouslySetInnerHTML={{
                          __html: htmlComments[pageIndex].comment,
                        }}
                      />
                    </div>
                  ) : null}
                </Grid>
              );
            })
          );
      } else {
        return alldata
          .filter((data) => data.page === parseInt(pageId))
          .map((page) =>
            _.sortBy(page.graphs, (i) => i.position).map((item, index) => {
              return (
                <Grid key={index + 1} item xs={whichSize(item.graphSize)}>
                  <main className="graphs">
                    <div
                      className="widget"
                      style={
                        width <= 640
                          ? {
                            display: "flex",
                            flexDirection: width <= 640 ? "column" : "row",
                          }
                          : {
                            marginLeft: 5,
                          }
                      }
                    >
                      <div style={{ overflowX: "auto" }}>
                        <div
                          style={{
                            padding: 7,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h4>{item.graphName}</h4>
                          {modifiable && !isPdf ? (
                            <div style={{ display: "flex" }}>
                              <EditIcon
                                onClick={(e) => editGraph(e, item.editLink)}
                                className="modifiableButton"
                              />
                              <DeleteIcon
                                onClick={(e) => deleteGraph(e, item.deleteLink)}
                                className="modifiableButton"
                              />
                            </div>
                          ) : null}
                        </div>
                        {setChart(
                          item.graphData,
                          item.keys,
                          item.graphType,
                          isPdf
                        )}
                      </div>
                    </div>
                  </main>
                </Grid>
              );
            })
          );
      }
    }
  };

  const display = (alldata = null, isPdf = false) => {
    if (alldata !== null) {
      return (
        <Grid container spacing={3}>
          {ToDisplay(allData, isPdf)}
        </Grid>
      );
    }
  };
  const { cabinet } = props;
  const dateDebut = new URLSearchParams(location.search).get("date_debut");
  const dateFin = new URLSearchParams(location.search).get("date_fin");

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const sendComment = async (editor) => {

    let content = editor.contentDocument.activeElement.innerHTML
    const { pageId } = props;

    const clonehtmlComments = [...htmlComments];
    const findObjIndex = clonehtmlComments.findIndex(
      (obj) => obj.page === parseInt(pageId)
    );
    clonehtmlComments[findObjIndex].comment = content;
    setHtml(clonehtmlComments);

    await api.postComment({
      id: data.pageId,
      content: content,
      date_debut: dateDebut,
      date_fin: dateFin,
    });
    handleClick();
  };
  const { pageId } = props;

  // const handleChange = (html) => {
  //   html = html.level.content;
  //   const clonehtmlComments = [...htmlComments];
  //   const findObjIndex = clonehtmlComments.findIndex(
  //     (obj) => obj.page === parseInt(pageId)
  //   );
  //   clonehtmlComments[findObjIndex].comment = html;
  //   setHtml(clonehtmlComments);
  // };
  const findObjIndex =   allData.findIndex( (obj) => obj.page === parseInt(pageId));
  const nbGraphs = allData[findObjIndex].graphs.length;
  return (
    <div>
      <div>
        {display(allData)}
        {modifiable ? (
          <>
            <div className="commentSection" style={ nbGraphs === 0 ? { marginTop: 150} : null }>
              <Editor apiKey= 'jatk5scsq89n7ovq658l62ovx418scj7jrdqz851xm620c77'

                initialValue={
                  htmlComments.find((elem) => elem.page === parseInt(pageId))
                    .comment
                }
                init={{
                  selector: "#tinyEditor",
                  plugins: 'link template',
                  menubar: false,
                  init_instance_callback: function(editor) {
                    setEditor(editor);

                    // On place le cursor à la fin du contenu deja présent :
                    editor.focus(false);
                    editor.selection.select(editor.getBody(), true);
                    editor.selection.collapse(false);
                  },
                  toolbar: 'undo redo | h1 h2 fontfamily blocks| blockquote bold italic underline strikethrough | indent alignleft aligncenter alignright',
                  language: 'fr_FR',
                  language_url: fr_FR,
                  templates : htmlComments.find((elem) => elem.page === parseInt(pageId))
                    .allPageComments,
                  skin: 'bootstrap',
                  height: 300,
                  content_style: "body { font-size: 12pt; font-family: Arial; }",

                }}
              />
              <a onClick={() =>sendComment(editor)} className="btn-tableo right">
                <SaveIcon />
                <span style={{ fontSize: 15 }} className="text">
                  Enregistrer
                </span>
              </a>
              <a className="btn-tableo" onClick={editor ? () => editor.execCommand('mceTemplate') : null}>
                <AddIconWhite/>
                <span style={{fontSize: 15}} className="text">
                    Reporter commentaire précédent
                </span>
              </a>
            </div>
          </>
        ) : htmlComments.find((elem) => elem.page === parseInt(pageId))
          .comment !== "" &&
          htmlComments.find((elem) => elem.page === parseInt(pageId))
            .comment !== "<p><br></p>" ? (
          <main className="graphs">
            <div
              className="widget container"
              style={
                width <= 640
                  ? { display: "flex", flexDirection: "column" }
                  : null
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
                className={`widget ${width <= 640 ? "large" : "medium"}`}
              >
                <h4>Commentaire :</h4>
                <div
                  style={{ margin: 20 }}
                  dangerouslySetInnerHTML={{
                    __html: htmlComments.find(
                      (elem) => elem.page === parseInt(pageId)
                    ).comment,
                  }}
                />
              </div>
            </div>
          </main>
        ) : null}
      </div>
      <PdfExport
        cabinet={cabinet}
        display={display}
        allData={allData}
        htmlComments={htmlComments}
        classes={classes}
        open={open}
        handleClose={handleClose}
        Alert={Alert}
        pdfExportComponent={pdfExportComponent}
        container={container}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  active: state.tabs.active,
});

export default connect(mapStateToProps)(Charts);
