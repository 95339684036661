import React, { useEffect, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import uuid from "react-uuid";
import _ from "lodash";
import moment from "moment";
import { eurFormatter, numFormat } from "../../../utils/formatNumber";
import useWindowDimensions from "../../hooks/useWindowDimensions";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Table = (props) => {
  const { data: graphData, keys, isPdf } = props;
  const [copySuccess, setCopySuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [monthDiff, setMonthDifference] = useState(0);
  const [firstTableData, setFirstTableData] = useState([]);
  const [secondTableData, setSecondTableData] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const { width } = useWindowDimensions();
  const [data, setData] = useState([]);

  const startdate = moment(
    `
    01-${new URLSearchParams(location.search).get("date_debut")}
  `,
    "DD-MM-YYYY"
  );
  const endDate = moment(
    `01-${new URLSearchParams(location.search).get("date_fin")}`,
    "DD-MM-YYYY"
  );

  useEffect(() => {
    setMonthDifference(endDate.diff(startdate, "months") + 1);
  }, [startdate, endDate]);

  useEffect(() => {
    if (monthDiff > 12) {
      const totalAmountArray = [];
      graphData.map((item) => {
        totalAmountArray.push(item.data.reduce((a, b) => a + b, 0));
      });
      setTotalAmount(totalAmountArray);
    }
  }, [graphData, monthDiff]);

  useEffect(() => {
    if (monthDiff > 12) {
      let newGraphData = [];
      // Map de tous les indicateur et construction d'une nouvelle structure de données
      graphData.map((indicateur) => {
        if (indicateur.data.length > 12) {
          newGraphData.push({
            ...indicateur,
            position: 1,
            data: _.chunk(indicateur.data, 12)[0],
          });
          newGraphData.push({
            ...indicateur,
            position: 2,
            data: _.chunk(indicateur.data, 12)[1],
          });
        }
        return;
      });
      setFirstTableData(newGraphData.filter((table) => table.position === 1));
      setSecondTableData(newGraphData.filter((table) => table.position === 2));
    }
  }, [graphData, monthDiff]);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const copyToClipBoard = async (copyMe, unite) => {
    try {
      await navigator.clipboard.writeText(unite !== "k\u20ac" ? copyMe : eurFormatter.format(copyMe));
      setCopySuccess(
        `Valeur copié dans votre presse papier : ${copyMe === 0 ? 0 : unite !== "k\u20ac" ? numFormat.format(copyMe) : eurFormatter.format(copyMe)
        } !`
      );
      setOpen(true);
    } catch (err) {
      setCopySuccess("Erreur lors de la copie");
    }
  };

  const displayTotal = (total, unite) => {
    if (total === 0 || total === "") {
      return total === 0 ? 0 : "";
    }
    return isNaN(total) ? "N/A" : unite !== "k\u20ac" ? numFormat.format(total.toFixed(2)) : eurFormatter.format(total);
  };

  const computeTotal = (graph, totalToDisplay = null, idx = null) => {
    if (graph.unite === "%" || graph.cumul) {
      return graph.unite === "%" ? graph.data.reduce((a, b) => a + b, 0) / graph.data.length : graph.data[graph.data.length - 1];
    } else {
      if (totalToDisplay) {
        return totalToDisplay[idx];
      } else {
        return graph.data.reduce((a, b) => a + b, 0);
      }
    }
  };
  //graph.data.reduce((a, b) => a + b, 0) / graph.data.length : graph.data[graph.data.length - 1];
  const Column = (chunkedData = null) => {
    if (!_.isEmpty(chunkedData)) {
      return chunkedData.map((columnName) => {
        return <th key={uuid()}>{columnName}</th>;
      });
    } else {
      return keys.map((columnName) => {
        return <th key={uuid()}>{columnName}</th>;
      });
    }
  };

  const Rows = (chunkedData = null, totalToDisplay = null) => {
    const tablesToIterate = !_.isEmpty(chunkedData) ? chunkedData : graphData;
    return tablesToIterate.map((graph, idx) => (
      <tr key={uuid()}>
        <th style={{ color: graph.backgroundColor, minWidth: "120px" }}>{`${graph.label
          } (${graph.unite}${graph.cumul ? " - cumulé" : ""})`}</th>
        {graph.data.map((value) => {
          return (
            <td
              style={{
                color: graph.backgroundColor,
                cursor: "pointer",
                textAlign: "right",
                padding: isPdf ? 5 : width <= 1300 ? 8 : 12,
                minWidth: isPdf ? "30px" : "80px",
              }}
              onClick={() =>
                copyToClipBoard(
                  isNaN(value) ? value : value !== 0 ? value.toFixed(2) : 0, graph.unite
                )
              }
              key={uuid()}
            >
              {isNaN(value)
                ? value
                : value !== 0
                  ? graph.unite !== "k\u20ac" ? numFormat.format(value.toFixed(2)) : eurFormatter.format(value)
                  : 0}
            </td>
          );
        })}
        {totalToDisplay ? (
          <th
            style={{
              color: graph.backgroundColor,
              cursor: "pointer",
              textAlign: "right",
              minWidth: "85px",
            }}
          >
            {!_.isEmpty(totalToDisplay)
              ? (displayTotal(computeTotal(graph, totalToDisplay, idx), graph.unite))
              : displayTotal(graph.totalData, graph.unite)}
          </th>
        ) : null}
      </tr>
    ));
  };

  const displayTables = () => {
    if (monthDiff > 12) {
      const chunkedColumn = _.chunk(keys, 12);
      return (
        <div className="multiple-tables" key={uuid()}>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Indicateur</th>
                  {Column(chunkedColumn[0])}
                </tr>
              </thead>
              <tbody>{Rows(firstTableData)}</tbody>
            </table>
            <br />
          </div>

          <div
            className="table-responsive"
            style={{ marginTop: isPdf ? 20 : 0 }}
          >
            <table>
              <thead>
                <tr>
                  <th>Indicateur</th>
                  {Column(chunkedColumn[1])}
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>{Rows(secondTableData, totalAmount)}</tbody>
            </table>
            <br />
          </div>
        </div>
      );
    }
    return (
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Indicateur</th>
              <Column />
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <Rows />
          </tbody>
        </table>
        <br />
      </div>
    );
  };

  if (_.isEmpty(graphData)) {
    return null;
  }

  return (
    <div
      className={`widget graph${monthDiff > 12 ? "-column" : ""} ${graphData.length === 1
        ? "table-small"
        : graphData.length > 4 || monthDiff > 12
          ? "table-large"
          : "table-medium"
        }`}
      style={
        isPdf
          ? {
            width: "100%",
            height: monthDiff > 12 ? graphData.length * 190 : 350,
          }
          : {
            height: `${width <= 1500
              ? 100
              : monthDiff > 12
                ? graphData.length * 27
                : graphData.length * 6
              }${width <= 1500 ? "%" : "vh"}`,
            width: width <= 1300 ? "max-content" : "",
          }
      }
    >
      {displayTables()}
      <Snackbar open={open} autoHideDuration={2800} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={
            copySuccess.includes("Valeur copié dans votre presse papier")
              ? "success"
              : "error"
          }
        >
          {copySuccess}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Table;
