import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton'
import useWindowDimensions from '../hooks/useWindowDimensions'
import _ from 'lodash'

function tabsLoading(width) {
  return (
    <>
      <div className="tabsTitles">
        <ul
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <li style={{ display: 'flex', marginRight: 10 }}>
            <Skeleton variant="text" width={150} height={20} />
          </li>
          <li style={{ display: 'flex', marginLeft: 10 }}>
            <Skeleton variant="text" width={150} height={20} />
          </li>
        </ul>
      </div>
      <div className="tabsPanels" style={{ margin: 20, marginTop: 68 }}>
        <div>
          <div style={{ marginTop: 15 }}>
            <Skeleton variant="text" width={350} height={30} />
            <Skeleton variant="rect" height={300} />
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: width < 640 ? 'column' : 'row',
                marginTop: 15,
              }}
            >
              <div
                style={
                  width < 640
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                      }
                    : null
                }
              >
                <Skeleton variant="text" width={150} height={30} />
                <Skeleton
                  variant="rect"
                  width={width < 640 ? null : width / 2 - 25}
                  height={250}
                />
              </div>
              <div
                style={
                  width < 640
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                      }
                    : null
                }
              >
                <Skeleton variant="text" width={150} height={30} />
                <Skeleton
                  variant="rect"
                  width={width < 640 ? null : width / 2 - 25}
                  height={250}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Tabs(props) {
  const { selected, active } = props
  const [selectedState, setActive] = useState(
    active !== null ? active : selected,
  )
  const { width } = useWindowDimensions()

  const mounted = useRef()
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      setActive(active)
    } else {
      setActive(active)
    }
  })

  const currentSelect = (id) => () => {
    const { history, ACTIVE_SELECTED, modifiable, dateDebut, dateFin } = props
    setActive(id)
    ACTIVE_SELECTED(id)
    if (modifiable) {
      history.push(
        `?page=${
          id + 1
        }&date_debut=${dateDebut}&date_fin=${dateFin}&modif=${modifiable}`,
      )
    } else {
      history.push(
        `?page=${id + 1}&date_debut=${dateDebut}&date_fin=${dateFin}`,
      )
    }
  }

  const tabsTitles = () => {
    const { tabsPanels } = props
    const panels = []
    _.map(tabsPanels, (item, index) => {
      panels.push(
        <li
          key={index}
          className={selectedState === index ? 'active' : ''}
          onClick={currentSelect(index)}
        >
          {item.label}
        </li>,
      )
    })
    return (
      <>
        <ul>{panels}</ul>
      </>
    )
  }

  const tabsPanels = () => {
    const { tabsPanels, ACTIVE_SELECTED } = props
    if (tabsPanels[selectedState] === undefined) ACTIVE_SELECTED(0)
    if (
      tabsPanels[selectedState] !== undefined &&
      typeof tabsPanels[selectedState].display === 'function'
    ) {
      return _.map((tabsPanels), (item, index) => (
        <div key={index}>{selectedState === index ? item.display() : null}</div>
      ))
    }
    return _.map((tabsPanels), (item, index) => (
      <div key={index}>{selectedState === index ? item.display : null}</div>
    ))
  }

  const {
    loading, panels, subheader
  } = props
  if (loading) return tabsLoading(width)
  if (subheader) return <div className="tabsTitles">{tabsTitles()}</div>
  if (panels) return <div className="tabsPanels">{tabsPanels()}</div>
  return null
}

Tabs.propTypes = {
  tabsPanels: PropTypes.array,
  selected: PropTypes.number,
  active: PropTypes.number,
  ACTIVE_SELECTED: PropTypes.func.isRequired,
}

Tabs.defaultProps = {
  tabsPanels: [],
  selected: 0,
  active: null,
}

const mapStateToProps = (state) => ({
  active: state.tabs.active,
})

const mapDispatchToProps = (dispatch) => ({
  ACTIVE_SELECTED: (active) => dispatch({ type: 'ACTIVE_SELECTED', active }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tabs))
